import React from 'react'
import styled from 'styled-components'


const BaseGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 40px;
`

const BigGrid = styled.div`
  display: grid;
  grid-template-column: 1fr;
  grid-gap: 0;
`

const SmallGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
`

const OneBigRestSmallerGrid = ({ big, small }) => {
  return <BaseGrid>
    <BigGrid>
      {big}
    </BigGrid>
    <SmallGrid>
      {small}
    </SmallGrid>
  </BaseGrid>
}

export default OneBigRestSmallerGrid;