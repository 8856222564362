import React from 'react'
import { graphql } from "gatsby"
import PostersDetail from '../components/posters-detail';

export default PostersDetail

export const query = graphql`
  query KDMSK209292929MKAM($imagesGlob: String!){
    allFile(sort: {fields: relativePath}, filter: {relativePath: {glob: $imagesGlob}}) {
      nodes {
        childImageSharp {
          fluid {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`